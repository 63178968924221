import firebase from 'firebase';
import router from '../../router';

const state = {
  sidebar: {
    opened: false,
  },
  rightSidebar: {
    opened: false,
  },
  config: {
    palette: {
      primary: '#4ae387',
      danger: '#e34a4a',
      info: '#4ab2e3',
      success: '#db76df',
      warning: '#f7cc36',
      white: '#fff',
      black: '#000',
      fontColor: '#34495e',
      transparent: 'transparent',
      lighterGray: '#ddd',
    },
  },
  isLoading: true,
  user: null,
  role: {
    text: 'Admin Level I',
    value: '0',
  },
  status: null,
  error: null,
};

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setRole (state, payload) {
    state.role = payload;
  },
  setStatus(state, payload) {
    state.status = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  SET_RIGHT_SIDEBAR(state, payload) {
    state.rightSidebar.opened = payload;
  },
};

const getters = {
  role (state) {
    return state.role;
  },
}

const actions = {
  async setUser({commit}, payload) {
    //Default role is Admin Level I
    commit('setUser', {
      uid: payload.uid,
      displayName: payload.displayName,
      role: payload.role,
      email: payload.email,
      photoURL: payload.photoURL,
    });
    this.dispatch('updateRole', payload.uid)
    this.dispatch('fetchNotifications');
  },
  updateRole ({commit}, payload) {
    //payload will be the uid, use to get the role
    firebase.firestore().collection('users').doc(payload).get().then((doc) => {
      if (doc.exists) {
        const userData = doc.data();
        if(userData.role.value) {
          commit('setRole', userData.role);
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  },
  signInAction({commit}, payload) {
    return firebase.auth().signInWithEmailAndPassword(payload.email, payload.password).then((response) => {
      commit('setUser', response.user);
      // commit('setStatus', response.user)
      // commit('setError', null)
      router.push({path: '/'});
    }).catch((error) => {
      return error.message;
    });
  },
};


export default {
  state,
  mutations,
  actions,
  getters,
};
