const state = {
    evaluation: [],
    evaluationQuestions: [],
    checking: false,
  };
  
  const getters = {
    getAnswerById: (state)=>(id)=>{
      return state.evaluationQuestions.find((question)=>question.id === id);
    },
    evaluationQuestions: (state)=>{
      return state.evaluationQuestions;
    },
    checking: (state)=>{
      return state.checking;
    },
  };
  
  const mutations = {
    SET_ANSWER(state, payload) {
      const tempQuestions = state.evaluationQuestions.map((question)=>{
        if (question.id === payload.id) {
          if (Array.isArray(payload.answer)) {
            payload.answer = payload.answer.join('::');
          }
          return Object.assign({}, question, {answerChoice: payload.answer.toString()});
        }
        return question;
      });
      state.evaluationQuestions = tempQuestions;
    },
    SET_EVALUATION(state, payload) {
      state.evaluation = payload;
    },
    SET_EVAL_QUESTIONS(state, payload) {
      state.evaluationQuestions = payload;
    },
    SET_CHECKING(state) {
      state.checking = true;
    },
  };
  
  const actions = {
    setAnswer({commit}, payload) {
      commit('SET_ANSWER', payload);
    },
    setEvaluation({commit}, payload) {
      let questions = [];
      payload.forEach((section)=>{
        questions = [...questions, ...section.data.questions];
      });
      commit('SET_EVAL_QUESTIONS', questions);
      commit('SET_EVALUATION', payload);
    },
    setChecking({commit}) {
      commit('SET_CHECKING', true);
    },
  };
  
  export default {
    state, mutations, getters, actions,
  };
  