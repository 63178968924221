<template>
  <div style="position: relative;">
    <i
      @click="closeSidebar"
      style="position: absolute; top: 0; right: 5px;"
      class="closeIcon pointer va-icon va-button__content__icon va-button__content__icon-left fa fa-close va-icon--fixed"
    />
    <div class="app-navbar app-layout__navbar">
      Editing Controls - {{ component.displayName }}
    </div>
    <div v-if="component && component.id > 0">
      <div
        class="mb-4"
      >
        <div>
          <va-input
            label="Header:"
            v-model="component.data.header"
            @input="updateHeader"
          />
        </div>
        <div>
          <va-select
            v-if="component.type == 'section'"
            label="Accreditor Types (Multiple)"
            v-model="AccreditorChoice"
             multiple
            :tagMax="8"
            key-by="value"
            :options="AccreditorList"
            @input="updateAccreditors"
          />
        </div>
        <div @click="toggleRequired" >
          <va-checkbox
            label="Required"
            v-model="component.data.required"
          />
        </div>
        <div @click="toggleTicket"  v-if="component.type != 'section'">
          <va-checkbox
            label="Will Create Ticket?"
            v-model="component.data.toggleTicket"
          />
        </div>
        <div @click="toggleHidden">
          <va-checkbox
            label="Hidden Until Answer"
            v-model="component.data.hidden"
          />
        </div>
    
        <visible-requirement
          :component="component"
          v-if="component.data.hidden"
        />
        <div class="flex">
          <div class="text-center flex row align-content--space-around">
            <va-button
              @click="moveItem('up')"
              icon="fa fa-level-up"
            />

            <va-button
              @click="moveItem('down')"
              icon="fa fa-level-down"
            />
          </div>
        </div>
        <hr>
        <div
          v-if="component.type === 'checkList'"
          class="flex xs12"
        >
          <ul>
            <li
              class="mb-3"
              v-for="(option,index) in component.data.options"
              :key="index"
            >
              <div class="flex row">
                <div class="flex xs11">
                  <va-input
                    label="Option Label"
                    v-model="option.label"
                    class="mb-auto"
                    @input="updateItem"
                  />
                  <div class="flex row">
                    <div
                      class="flex xs10"
                      @click="makeOpenEnded(option)"
                    >
                      <va-input
                        label="Open Ended Placeholder"
                        v-model="option.openEndedPlaceholder"
                        v-if="option.openEnded"
                        @input="updateItem"
                      />
                      <va-checkbox
                        :label="'Open Ended'"
                        v-model="option.openEnded"
                      />
                      <div  @click="TicketTrigger(index)" >
                        <va-checkbox 
                          v-if="component.data.toggleTicket"
                          title="Check if you want this selection to create a ticket"
                          label="Create Ticket"
                          v-model="option.TicketTrigger"
                          style="margin-top: 7px;"
                        />
                      </div>
                    </div>
                    <div class="flex xs1">
                      <va-button
                        v-if="component.data.options.length > 1"
                        icon="fa fa-minus"
                        color="danger"
                        @click="removeOption(option)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div>
            <div class="float-right">
              <va-button
                icon="fa fa-plus"
                color="success"
                @click="addOption"
              >
                Add Option
              </va-button>
            </div>
          </div>
        </div>
        <div
          v-if="component.type === 'multiple'"
          class="flex xs12"
        >
          <ul>
            <li
              class="mb-3"
              v-for="(option,index) in component.data.options"
              :key="index"
            >
              <div class="flex row">
                <div :class="customClass(component.data)" >
                  <va-input
                    label="Option Label"
                    v-model="option.label"
                    class="mb-auto"
                    @input="updateItem"
                  />
                </div>
                <div class="flex row">
                  <div class="flex" @click="TicketTrigger(index)" >
                    <va-checkbox 
                      v-if="component.data.toggleTicket"
                      title="Check if you want this selection to create a ticket"
                      v-model="option.TicketTrigger"
                      style="margin-top: 7px;"
                    />
                  </div>
                  <div class="flex">
                    <va-button
                      :disabled="component.data.options.length < 2"
                      icon="fa fa-minus"
                      color="danger"
                      @click="removeOption(option)"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div>
            <div class="float-right">
              <va-button
                icon="fa fa-plus"
                color="success"
                @click="addOption"
              >
                Add Option
              </va-button>
            </div>
          </div>
        </div>




        <div
          v-if="component.type === 'trueFalse'"
          class="flex xs12"
        >
          <ul>
            <li
              class="mb-3"
              v-for="(option,index) in component.data.options"
              :key="index"
            >
              <div class="flex row">
                <div :class="customClass(component.data)" >
                  <va-input
                    label="Option Label"
                    v-model="option.label"
                    class="mb-auto"
                    @input="updateItem"
                  />
                </div>
                <div class="flex row">
                  <div class="flex" @click="TicketTrigger(index)" >
                    <va-checkbox 
                      v-if="component.data.toggleTicket"
                      title="Check if you want this selection to create a ticket"
                      v-model="option.TicketTrigger"
                      style="margin-top: 7px;"
                    />
                  </div>
                  <div class="flex">
                    <va-button
                      :disabled="component.data.options.length < 2"
                      icon="fa fa-minus"
                      color="danger"
                      @click="removeOption(option)"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        
        </div>





      </div>
    </div>
    <div v-if="false">
      <va-button
        icon="fa fa-save"
        @click="updateItem"
      />
    </div>
    <div v-if="debug">
      {{ component }}
      {{ $store.state.evaluation.activeEvalItem.id }}
    </div>
  </div>
</template>

<script>
import visibleRequirement from './visibleRequirement.vue';
import firebase from 'firebase';
export default {
  data() {
    return {

      ticketCategories: [],
      AccreditorChoice: [],
      AccreditorList: [],
      componentRevert: null,
      debug: false,
    };
  },
  components: {
    visibleRequirement,
  },
  computed: {
    component() {
      const activeComponentId = this.$store.state.evaluation.activeEvalItem;
      let editableComponent = this.$store.state.evaluation.evaluation.find((section)=>{
        return section.id === activeComponentId;
      });
      //get data from evalCategories coollection and save into this.ticketCategories

      if (!editableComponent) {
        this.$store.state.evaluation.evaluation.forEach((section) => {
          if (!editableComponent) {
            editableComponent = section.data.questions.find((question)=>{
              return question.id === activeComponentId;
            });
          }
        });
      }
      // component not found
      if (!editableComponent) {
        editableComponent = {id: 0};
      }
      //console.log('parsing editable component:', activeComponentId, editableComponent);
      return JSON.parse(JSON.stringify(editableComponent));
    },
    questions() {
      return this.$store.state.evaluation.evaluation.find((section)=>{
        return section.id === this.$store.state.evaluation.activeEvalItem;
      }).data['questions'];
    },
  }, 
  created () {
      this.updateAccreditations();
  },
  watch: {
    component: {
      handler() {
       this.AccreditorChoice = this.component.data.accreditor || [];
      },
      deep: true,
    },
  },
  methods: {
    updateAccreditations(){
      this.AccreditorList = [];
      let query = firebase.firestore().collection('credentials');
      query.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          this.AccreditorList.push({
            text: data.accreditorType,
            value: data.value
          });
        });
      });
      this.AccreditorChoice = this.component.data.accreditor || [];
    },
    updateAccreditors(){
      this.component.data.accreditor = this.AccreditorChoice;
      this.updateItem();
    },
    updateItem() {
      this.$store.dispatch('updateEvalItem', this.component);
    },
    closeSidebar() {
      this.$store.dispatch('clearEvalItem');
    },
    updateHeader(input) {
      this.component.data.header = input;
      this.updateItem();
    },
    toggleRequired() {
      this.component.data.required != this.component.data.required;
      this.updateItem();
    },
    TicketTrigger(index){
      this.component.data.options[index].TicketTrigger != this.component.data.options[index].TicketTrigger;
      this.updateItem();
    },
    customClass(data){
      if(data.toggleTicket){
        return 'flex xs7';
      }else{
        return 'flex xs9';
      }
    },
    toggleTicket() {
      if(this.component.data.toggleTicket){
        //show swal with options to select a category
        let options = this.$store.state.evaluation.categories;
        const inputOptions = options.reduce((o, i) => {
          o[i.id] = i.name
          return o
        }, {})
        //show sweet alert with select containing options
        this.$swal({
          title: 'Select a category',
          html: 'Not in the list? Create a new category <a href="/tickets/custom-categories">here</a>',
          input: 'select',
          inputOptions: inputOptions,
          confirmButtonText: 'Select <i class="fa fa-arrow-right"></i>',
          inputPlaceholder: 'Select a category',
          showCancelButton: true,
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value !== '') {
                resolve();
              } else {
                resolve('You need to select a category');
              }
            });
          },
        }).then((result) => {
          if (result.value) {
            this.component.data.category = result.value;
            this.updateItem();
          }
        });
      }else{
        this.component.data.category = null;
        this.updateItem();
      }
      this.component.data.Ticket != this.component.data.Ticket;
      this.updateItem();
    },
    toggleHidden() {
      this.component.data.hidden != this.component.data.hidden;
      this.updateItem();
    },
    makeOpenEnded(newOption) {
      this.updateItem();
      const newArray = this.component.data.options.map((option)=>{
        if (option == newOption) {
          return Object.assign({}, option, {openEnded: !newOption.openEnded});
        };

        return option;
      });
      this.component.data.options = newArray;
      this.updateItem();
    },
    addOption() {
      this.component.data.options = [...this.component.data.options, {
        'label': 'new option',
        'openEnded': false,
      }];
      this.updateItem();
    },
    removeOption(oldOption) {
      this.component.data.options = this.component.data.options.filter((option, index)=>{
        return option !== oldOption;
      });
      this.updateItem();
    },
    moveItem(direction) {
      if (this.component.type === 'section') {
        this.$store.dispatch('moveSection', direction);
      } else {
        this.$store.dispatch('moveQuestion', direction);
      }
    },
  },
};
</script>

<style>
  .closeIcon {
    color: gray;
  }

  .closeIcon:hover {
    color: blue;
  }
</style>
