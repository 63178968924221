export const originalTheme = Object.freeze({
  context: {
    'gradient': false,
    'shadow': 'sm',
    'invertedColor': true,
  },
  colors: {
    primary: '#6c7fee',
    secondary: '#6e7ff1',
    success: '#8ddc88',
    info: '#71baff',
    danger: '#f8706d',
    warning: '#ffd652',
    gray: '#8396a5',
    dark: '#34495e',
  },
})

export const nihssTheme = Object.freeze({
  context: {
    'gradient': false,
    'shadow': 'sm',
    'invertedColor': true,
  },
  colors: {
    primary: '#631212',
    secondary: '#8A2828',
    success: '#4D7931',
    info: '#0D6556',
    danger: '#B15654',
    warning: '#BFA02C',
    gray: '#863131',
    dark: '#303030',
  },
})
